import { RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { FC, useEffect } from 'react';
import { useUID } from 'react-uid';
import styled from 'styled-components';

import { Anchor } from '../components/Anchor';
import { CaseCount } from '../components/CaseCount';
import { Hero, HeroBackground } from '../components/Hero';
import { HeroContainer } from '../components/HeroContainer';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { H3 } from '../components/Typography';
import { UploadForm } from '../forms/UploadForm';
import { Title } from '../styles/common';
import { Colors } from '../utils/style-utils';

const Paragraph = styled.p`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333;
  font-size: 18px;
`;

const HowItWorks: FC<RouteComponentProps> = (props) => {
  const id = useUID();
  const staticData = useStaticQuery(graphql`
    query {
      sendIllustration: file(relativePath: { eq: "send-illustration.png" }) {
        childImageSharp {
          fixed(width: 315) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const scrollToDiv = document.getElementById(id);
      scrollToDiv?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.location]);

  return (
    <PublicRoute {...props}>
      <SEO title="How It Works" description="A down-to-earth explanation on how Hypercite works" />
      <Hero className="flex flex-col items-center w-full">
        <Title className="text-center mt-16 mb-12">
          Automatically Hyperlink Your Citations to the Record, Prior Briefing, and More In Just Seconds
        </Title>
        <HeroBackground />
        <div className="w-full flex px-4">
          <div className="w-full md:w-2/3 lg:w-1/2 ml-auto mr-auto">
            <UploadForm />
          </div>
        </div>

        <CaseCount />
      </Hero>

      <HeroContainer id={id}>
        <div className="flex flex-col ml-auto mr-auto max-w-screen-xl">
          <div className="p-6">
            <H3 className="mt-20 mb-4 font-light" color={Colors.Purple300}>
              For Attorneys and Lawfirms
            </H3>

            <Paragraph>
              HyperCite<sup>&reg;</sup> is web-based software that autonomously combines an appellate brief, motion, or
              other filing with the record on appeal. Once combined, HyperCite<sup>&reg;</sup> simultaneously creates
              hyperlinks between textual record citations in the brief, petition, motion, or otherwise, to the exact
              pages cited contained within the record on appeal. The record on appeal for HyperCite
              <sup>&reg;</sup> hyperlinking purposes does not just include items such as a Reporter’s Transcript,
              Clerk’s Transcript, appendices, or exhibits. HyperCite
              <sup>&reg;</sup>
              also hyperlinks citations that cite to previous briefing, motions, or any other conceivable source
              document.
            </Paragraph>
          </div>

          <div className="flex items-center justify-center pt-16">
            <Img fixed={staticData.sendIllustration.childImageSharp.fixed} />
          </div>

          <div className="flex flex-row mt-20" style={{ marginBottom: 140 }}>
            <div className="flex-1 p-6">
              <H3 className="mb-4 font-light" color={Colors.Purple300}>
                Free to Use
              </H3>

              <Paragraph>
                HyperCite<sup>&reg;</sup> is free to use. You can cite as many documents as you wish. Want to help make
                HyperCite<sup>&reg;</sup> better? <Anchor href="mailto:help@hypercite.com">Email us</Anchor> and let us
                know how we can make it better.
              </Paragraph>
            </div>

            <div className="flex-1 p-6">
              <H3 className="mb-4 font-light" color={Colors.Purple300}>
                Filing in California*
              </H3>

              <Paragraph>
                Currently, you may file any hyperlinked brief, motion, petition, or etc. in a California Court of Appeal
                or the California Supreme Court through TrueFiling where the combined parent document (ie. brief) and
                record do not exceed 25-megabytes.
              </Paragraph>
            </div>
          </div>
        </div>
      </HeroContainer>
    </PublicRoute>
  );
};

export default HowItWorks;
